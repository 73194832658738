import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getData } from 'store/layout/actions';
import MainRoutes from 'routes/MainRoutes';
import Head from './components/Head';
import Header from './components/Header';
import Footer from './components/Footer';

import 'assets/css/vendor/normalize.css';
import 'assets/css/vendor/font-awesome.css';
import 'assets/css/hitech/ht-base.css';
import 'assets/css/style.css';

function LayoutContainer() {
  const dispatch = useDispatch();
  const info = useSelector((state) => state.layout.info);
  const seo = useSelector((state) => state.layout.seo);
  const menu = useSelector((state) => state.layout.menu);

  useEffect(() => {
    dispatch(getData());
  }, [dispatch]);

  return (
    <>
      <Head info={info} seo={seo} />
      <Header menu={menu} active={seo.active} />
      <MainRoutes />
      <Footer info={info} />
    </>
  );
}

export default React.memo(LayoutContainer);
