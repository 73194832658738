import helper from 'store/helper';
import * as types from 'store/home/types';

const initialState = {
  status: 'idle',
  aboutUs: {},
  chooseUs: { name: '', items: [] },
  products: [],
  feedbacks: [],
  customers: [],
  news: [],
};

const setData = (state, action) => {
  return {
    ...state,
    aboutUs: action.payload.aboutUs,
    chooseUs: action.payload.chooseUs,
    products: action.payload.products,
    feedbacks: action.payload.feedbacks,
    customers: action.payload.customers,
    news: action.payload.news,
    status: 'succeeded',
  };
};

const setStatus = (state, action) => {
  return {
    ...state,
    status: action.payload.status,
  };
};

const HomeReducer = helper.createReducer(initialState, {
  [types.SET_DATA]: setData,
  [types.SET_STATUS]: setStatus,
});

export default HomeReducer;
