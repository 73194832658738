import { spawn, all, call } from 'redux-saga/effects';

import layoutSagas from 'store/layout/sagas';
import homeSagas from 'store/home/sagas';
import htmlSagas from 'store/html/sagas';
import newsSagas from 'store/news/sagas';
import productSagas from 'store/product/sagas';

const sagas = [layoutSagas, homeSagas, htmlSagas, newsSagas, productSagas];

export default function* rootSaga() {
  // keeping all sagas running
  // if one crash, restart it
  yield all(
    sagas.map((saga) =>
      spawn(function* () {
        while (true) {
          try {
            yield call(saga);
            break;
          } catch (e) {
            console.log(e);
          }
        }
      })
    )
  );
}
