import { call, put, takeLatest, all, select, take } from 'redux-saga/effects';
import axios from 'axios';
import * as types from 'store/home/types';
import * as actions from 'store/home/actions';
import * as layoutTypes from 'store/layout/types';
import * as layoutActions from 'store/layout/actions';
import * as layoutSelectors from 'store/layout/selectors';

const fetchHtml = async (id) => {
  const res = await axios
    .get(`https://testreact.webdesign.edu.vn/api/html/id=${id}`)
    .then((response) => response)
    .catch((error) => {
      console.log(error);
    });

  return res;
};

const fetchProduct = async () => {
  const res = await axios
    .get(`https://testreact.webdesign.edu.vn/api/product/root_id=1?limit=2`)
    .then((response) => response)
    .catch((error) => {
      console.log(error);
    });

  return res;
};

const fetchFeedback = async () => {
  const res = await axios
    .get(`https://testreact.webdesign.edu.vn/api/news/root_id=3`)
    .then((response) => response)
    .catch((error) => {
      console.log(error);
    });

  return res;
};

const fetchCustomer = async () => {
  const res = await axios
    .get(`https://testreact.webdesign.edu.vn/api/news/root_id=2`)
    .then((response) => response)
    .catch((error) => {
      console.log(error);
    });

  return res;
};

const fetchNews = async () => {
  const res = await axios
    .get(`https://testreact.webdesign.edu.vn/api/news/root_id=1?limit=6`)
    .then((response) => response)
    .catch((error) => {
      console.log(error);
    });

  return res;
};

function* getData() {
  try {
    yield put(actions.setStatus('loading'));
    const [
      aboutUs,
      chooseUs,
      products,
      feedbacks,
      customers,
      news,
    ] = yield all([
      call(fetchHtml, 1),
      call(fetchHtml, 2),
      call(fetchProduct),
      call(fetchFeedback),
      call(fetchCustomer),
      call(fetchNews),
    ]);
    yield put(
      actions.setData({
        aboutUs: {
          firstTitle: aboutUs.data.multi_input['tieu-de-1'],
          lastTitle: aboutUs.data.multi_input['tieu-de-2'],
          descShort: aboutUs.data.multi_detail['mo-ta'],
          thumbnail: aboutUs.data.thumbnail,
          statistics: aboutUs.data.sub,
        },
        chooseUs: {
          name: chooseUs.data.name,
          items: chooseUs.data.sub,
        },
        products: products.data,
        feedbacks: feedbacks.data,
        customers: customers.data,
        news: news.data,
      })
    );

    const status = yield select(layoutSelectors.getStatus);
    if (status !== 'succeeded') {
      yield take(layoutTypes.SET_DATA);
    }
    const menu = yield select(layoutSelectors.getMenu);
    const menuActive = menu.find((item) => item.active === 'home') || {};
    const { title, description, keyword, active } = menuActive;

    yield put(
      layoutActions.setSeo({
        title,
        description,
        keyword,
        active,
      })
    );
  } catch (e) {
    console.log(e);
  }
}

export default function* homeSagas() {
  yield takeLatest(types.GET_DATA, getData);
}
