import React from 'react';
import { Link } from 'react-router-dom';
import { oneOfType, array, string } from 'prop-types';
import classNames from 'classnames';

function Header(props) {
  const { menu, active } = props;

  return (
    <div className="s-header-15">
      <div className="container align-items">
        <Link to="/" className="hd-logo">
          <img src="images/logo.png" alt="logo" />
        </Link>
        <div className="hd-menu align-items">
          <a href="javascript:;" ht-trigger="hd-menu">
            <span>Menu</span>
            <i className="fa fa-bars fa-transformation" aria-hidden="true" />
          </a>
          <a href="javascript:;" ht-trigger="hd-show">
            <i className="fa fa-bars fa-transformation" aria-hidden="true" />
          </a>
          <ul ht-target="hd-menu">
            {menu.map((item, index) => {
              const liClass = classNames({
                'is-active': item.active === active,
              })
              return (
                <li key={`menu-${index + 1}`} className={liClass}>
                  <Link to={`/${item.link}`}>
                    <h2>{item.name}</h2>
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
        <div className="hd-search">
          <i
            className="fa fa-search fa-transformation"
            aria-hidden="true"
            ht-trigger="hd-search"
          />
          <form
            className="b-search-2"
            action=""
            method="get"
            acceptCharset="utf-8"
          >
            <input
              type="text"
              name="keysearch"
              placeholder="Search product ..."
              value=""
            />
            <button type="submit" className="c-btn--primary">
              <i className="fa fa-search" aria-hidden="true" />
            </button>
          </form>
        </div>
        <div className="hd-language">
          <a href="">
            <img src="images/icons/vi.jpg" alt="" />
          </a>
          <a href="">
            <img src="images/icons/en.jpg" alt="" />
          </a>
        </div>
      </div>
    </div>
  );
}

Header.propTypes = {
  menu: oneOfType([array]),
  active: string,
};

Header.defaultProps = {
  menu: [],
  active: [],
};

export default Header;
