import * as types from 'store/product/types';
import helper from 'store/helper';

export const setStatus = (status) =>
  helper.createAction(types.SET_STATUS, status);

export const getList = () => helper.createAction(types.GET_LIST);
export const setList = (data) => helper.createAction(types.SET_LIST, data);

export const getDetail = (id) => helper.createAction(types.GET_DETAIL, id);
export const setDetail = (data) => helper.createAction(types.SET_DETAIL, data);
