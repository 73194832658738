import helper from 'store/helper';
import * as types from 'store/layout/types';

const initialState = {
  status: 'idle',
  seo: {},
  info: {},
  menu: [],
};

const setData = (state, action) => {
  return {
    ...state,
    info: action.payload.info,
    menu: action.payload.menu,
    status: 'succeeded',
  };
};

const setStatus = (state, action) => {
  return {
    ...state,
    status: action.payload,
  };
};
const setSeo = (state, action) => {
  return {
    ...state,
    seo: action.payload,
  };
};

const LayoutReducer = helper.createReducer(initialState, {
  [types.SET_STATUS]: setStatus,
  [types.SET_DATA]: setData,
  [types.SET_SEO]: setSeo,
});

export default LayoutReducer;
