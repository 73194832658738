import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Slider from 'react-slick';
import { getData } from 'store/home/actions';
import { Link } from 'react-router-dom';

import 'assets/css/slick.css';
import 'assets/css/home.css';

function HomeContainer() {
  const dispatch = useDispatch();
  const info = useSelector((state) => state.layout.info);
  const aboutUs = useSelector((state) => state.home.aboutUs);
  const chooseUs = useSelector((state) => state.home.chooseUs);
  const products = useSelector((state) => state.home.products);
  const feedbacks = useSelector((state) => state.home.feedbacks);
  const customers = useSelector((state) => state.home.customers);
  const news = useSelector((state) => state.home.news);

  useEffect(() => {
    dispatch(getData());
  }, [dispatch]);

  return (
    <>
      <div className="s-video-1">
        <video autoPlay loop muted playsinline preload="metadata">
          <source src="https://joesgaragecoffee.com/content/themes/joesgaragecoffee/assets/video/header_22-sec_no-lines.mp4" />
        </video>
        <div className="s_caption">
          <h5 className="s_sub">{info?.multi_input?.slogan?.name}</h5>
          <h2 className="s_name">{info?.multi_input?.slogan?.desc_short}</h2>
          <a
            href="/contact-51.html"
            data-text={info?.multi_input?.hotline}
            className="c-button-17"
          >
            <span>Contact us now</span>
          </a>
        </div>
      </div>
      <div className="s-about-1">
        <img className="s_line" src="images/bgs/shape.svg" alt="" />
        <div className="container">
          <div className="s_box">
            <div className="row">
              <div className="col-md-4">
                <div className="s_thumb ht-ratio-75">
                  <img
                    src={`${process.env.REACT_APP_HOST}/uploads/images/2021/08/autox450-${aboutUs.thumbnail}`}
                    alt={`${aboutUs.firstTitle} ${aboutUs.lastTitle}`}
                  />
                </div>
              </div>
              <div className="col-md-8">
                <div className="s_content">
                  <h3 className="s_title">
                    {aboutUs.firstTitle}
                    <span>{aboutUs.lastTitle}</span>
                  </h3>
                  <div
                    className="ht-format-detail"
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{ __html: aboutUs.descShort }}
                  />
                  <div className="s_number">
                    <div className="row">
                      {aboutUs?.statistics?.map((item, index) => {
                        const multiInput = JSON.parse(item.multi_input);
                        return (
                          <div key={`s_number-${index + 1}`} className="col-4">
                            <div className="b-card-3">
                              <div className="b_icon">
                                <i
                                  className={`fa ${item.icon}`}
                                  aria-hidden="true"
                                />
                              </div>
                              <div className="b_content">
                                <h3 className="b_title">{item.name}</h3>
                                <h4 className="b_desc">
                                  {multiInput['so-lieu']}
                                </h4>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="s_why">
            <div className="c-headline-11">
              <h2 className="c_name">{chooseUs.name}</h2>
            </div>
            <div className="row row-flex">
              {chooseUs.items.map((item) => {
                return (
                  <div className="col-12 col-xs-6 col-md-3">
                    <div className="b-card-37">
                      <div className="b_icon">
                        <i className={`fa ${item.icon}`} aria-hidden="true" />
                      </div>
                      <h3 className="b_title">{item.name}</h3>
                      <div className="b_desc">{item.desc_short}</div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="s-product-1">
        <div className="container">
          <h2 className="s_title">Featured products</h2>
          <div className="b-sslide-default">
            <div className="slick-slider">
              {products.map((item) => {
                return (
                  <div className="item">
                    <Link
                      to={`${item.slug}-${item.id}-15.html`}
                      className="b-card-77"
                    >
                      <div className="row">
                        <div className="col-lg-5">
                          <div className="b_content">
                            <div className="b_sub">{item.cat_name}</div>
                            <h3 className="b_title">{item.name}</h3>
                            <div className="ht-format-detail">
                              {item.detail}
                            </div>
                            <div className="b_more">
                              Show more
                              <i
                                className="fa fa-long-arrow-right"
                                aria-hidden="true"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-7">
                          <div className="b_thumb ht-ratio--100">
                            <img
                              src={`${process.env.REACT_APP_HOST}/uploads/images/2021/08/296x296-${item.thumbnail}`}
                              alt={item.name}
                              className="ht-ratio__img"
                            />
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="s-home-5">
        <div className="container">
          <div className="s_box">
            <div className="b-sslide-default">
              <Slider slidesToShow={1} slidesToScroll={1}>
                {feedbacks.map((feedback) => {
                  return (
                    <div className="b-card-9 item">
                      <div className="b_icon">
                        <i className="fa fa-comments" aria-hidden="true" />
                      </div>
                      <div className="b_desc">{feedback.desc_short}</div>
                      <div className="b_author">
                        <span>{feedback.name}</span>
                        <span>{feedback.link}</span>
                      </div>
                      <div className="b_avatar">
                        <img
                          src={`${process.env.REACT_APP_HOST}/uploads/images/2021/08/140x140-${feedback.thumbnail}`}
                          alt={feedback.name}
                        />
                      </div>
                    </div>
                  );
                })}
              </Slider>
            </div>
          </div>
        </div>
      </div>
      <div className="s-home-32">
        <div className="container">
          <div className="s_box">
            <div className="b-sslide-default">
              <Slider
                autoplay
                autoplaySpeed={1000}
                slidesToShow={6}
                slidesToScroll={1}
                arrows={false}
              >
                {customers.map((item) => {
                  return (
                    <div className="item">
                      <a href="/">
                        <img
                          src={`${process.env.REACT_APP_HOST}/uploads/images/2021/08/130x130-${item.thumbnail}`}
                          alt={item.name}
                        />
                      </a>
                    </div>
                  );
                })}
              </Slider>
            </div>
          </div>
        </div>
      </div>
      <div className="s-news-1">
        <div className="container">
          <div className="c-headline-11">
            <h2 className="c_name">Featured News</h2>
          </div>
          <div className="row row-flex">
            {news.map((item) => {
              return (
                <div className="col-6 col-md-4">
                  <Link
                    to={`${item.slug}-${item.id}-25.html`}
                    className="b-card-42"
                  >
                    <div className="b_top">
                      <div className="b_thumb ht-ratio--75">
                        <img
                          src={`${process.env.REACT_APP_HOST}/uploads/images/2021/08/385x289-${item.thumbnail}`}
                          alt={item.name}
                          className="ht-ratio__img"
                        />
                      </div>
                    </div>
                    <div className="b_date">
                      <i className="fa fa-calendar-o" aria-hidden="true" />
                      {item.date_created}
                    </div>
                    <h3 className="b_title">{item.name}</h3>
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}

export default HomeContainer;
