import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Slider from 'react-slick';
import { string } from 'prop-types';
import { getData } from 'store/html/actions';

import 'assets/css/slick.css';
import 'assets/css/about-us.css';

const ContentBox = ({ headline, title, content, file }) => {
  return (
    <div className="s_box">
      <div className="s_sub">{headline}</div>
      <h2 className="s_title">{title}</h2>
      <div
        className="ht-format-detail"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: content }}
      />
      {file && (
        <div className="s_button">
          <a
            href={`${process.env.REACT_APP_HOST}/uploads/files/2021/08/${file}`}
            className="c-button-4"
          >
            <span className="fa fa-download">Download Profile</span>
          </a>
        </div>
      )}
    </div>
  );
};

ContentBox.propTypes = {
  headline: string,
  title: string,
  content: string,
  file: string,
};

ContentBox.defaultProps = {
  headline: '',
  title: '',
  content: '',
  file: '',
};

function HtmlContainer() {
  const dispatch = useDispatch();
  const aboutUs = useSelector((state) => state.html.aboutUs);
  const chooseUs = useSelector((state) => state.html.chooseUs);

  const contentBoxes = [
    {
      name: aboutUs.overviewName,
      detail: aboutUs.overviewContent,
    },
    {
      name: aboutUs.profileName,
      detail: aboutUs.profileContent,
      file: aboutUs.profileDownload,
    },
    ...(aboutUs.information || []),
  ];

  const slider = (aboutUs?.images || []).reduce(
    (acc, item, index) => {
      const key = index % 2 === 0 ? 'slider1' : 'slider2';
      return {
        ...acc,
        [key]: [...acc[key], item],
      };
    },
    { slider1: [], slider2: [] }
  );

  useEffect(() => {
    dispatch(getData());
  }, [dispatch]);

  return (
    <>
      <div className="s-banner-1">
        <div className="container">
          <div className="s_content">
            <h2 className="title">About us</h2>
            <ul className="s-breadcrumb-1">
              <li>
                <a href="index.php">Home</a>
              </li>
              <li>
                <span>About us</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="s-about-2">
        <div className="container">
          {contentBoxes.map((item, index) => {
            return (
              <ContentBox
                key={`box-${index + 1}`}
                headline={aboutUs.headline}
                title={item.name}
                content={item.detail}
                file={item.file}
              />
            );
          })}
        </div>
      </div>
      <div className="s-why-1">
        <div className="container">
          <div className="c-headline-11">
            <h2 className="c_name">{chooseUs.name}</h2>
          </div>
          <div className="row row-flex">
            {chooseUs?.items?.map((item, index) => {
              return (
                <div
                  key={`why-${index + 1}`}
                  className="col-12 col-xs-6 col-md-3"
                >
                  <div className="b-card-37">
                    <div className="b_icon">
                      <i className={`fa ${item.icon}`} aria-hidden="true" />
                    </div>
                    <h3 className="b_title">{item.name}</h3>
                    <div className="b_desc">{item.desc_short}</div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <div className="s-home-5">
        <div className="container">
          <div className="c-headline-11">
            <h2 className="c_name">Certificate</h2>
          </div>
          <div className="b-sslide-default">
            <Slider
              autoplay
              autoplaySpeed={1000}
              slidesToShow={3}
              slidesToScroll={1}
            >
              {aboutUs?.certificate?.map((item) => {
                return (
                  <div className="item">
                    <div className="s_thumb ht-ratio--135">
                      <img
                        src={`${process.env.REACT_APP_HOST}/uploads/images/2021/08/455x599-${item.thumbnail}`}
                        alt={item.name}
                        className="ht-ratio__img"
                      />
                    </div>
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>
      </div>

      <div className="s-section-10">
        <div className="container-fluid">
          <div className="b-sslide-default">
            <Slider
              autoplay
              slidesToShow={4}
              slidesToScroll={1}
              arrows={false}
              className="slider1"
            >
              {slider.slider1.map((item, index) => {
                return (
                  <div key={`slide1-${index + 1}`} className="item">
                    <a
                      href="images/about/img-<?php echo $i ?>.png"
                      className="b-card-38"
                    >
                      <div className="b_thumb ht-ratio--75">
                        <img
                          src={`${process.env.REACT_APP_HOST}/uploads/images/2021/08/${item.thumbnail}`}
                          alt={item.name}
                          className="ht-ratio__img"
                        />
                      </div>
                      <div className="b_icon">
                        <i className="fa fa-plus" aria-hidden="true" />
                      </div>
                    </a>
                  </div>
                );
              })}
            </Slider>
          </div>
          <div className="b-sslide-default">
            <Slider
              autoplay
              slidesToShow={3}
              slidesToScroll={1}
              arrows={false}
              className="slider2"
            >
              {slider.slider2.map((item, index) => {
                return (
                  <div key={`slide2-${index + 1}`} className="item">
                    <a
                      href="images/about/img-<?php echo $i ?>.png"
                      className="b-card-38"
                    >
                      <div className="b_thumb ht-ratio--75">
                        <img
                          src={`${process.env.REACT_APP_HOST}/uploads/images/2021/08/${item.thumbnail}`}
                          alt={item.name}
                          className="ht-ratio__img"
                        />
                      </div>
                      <div className="b_icon">
                        <i className="fa fa-plus" aria-hidden="true" />
                      </div>
                    </a>
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>
      </div>
    </>
  );
}

export default HtmlContainer;
