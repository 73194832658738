import { call, put, takeLatest, all } from 'redux-saga/effects';
import axios from 'axios';
import * as types from 'store/layout/types';
import * as actions from 'store/layout/actions';

const fetchInfo = async () => {
  const res = await axios
    .get(`https://testreact.webdesign.edu.vn/api/info/id=1`)
    .then((response) => response)
    .catch((error) => {
      console.log(error);
    });

  return res;
};

const fetchMenu = async () => {
  const res = await axios
    .get(`https://testreact.webdesign.edu.vn/api/menu`)
    .then((response) => response)
    .catch((error) => {
      console.log(error);
    });

  return res;
};

function* getData() {
  try {
    yield put(actions.setStatus('loading'));
    const [info, menu] = yield all([call(fetchInfo), call(fetchMenu)]);
    yield put(
      actions.setData({
        info: info.data,
        menu: menu.data,
      })
    );
  } catch (e) {
    console.log(e);
  }
}

export default function* layoutSagas() {
  yield takeLatest(types.GET_DATA, getData);
}
