import helper from 'store/helper';
import * as types from 'store/product/types';

const initialState = {
  status: 'idle',
  list: [],
  detail: {},
};

const setStatus = (state, action) => {
  return {
    ...state,
    status: action.payload,
  };
};

const setList = (state, action) => {
  return {
    ...state,
    list: action.payload,
    status: 'succeeded',
  };
};

const setDetail = (state, action) => {
  return {
    ...state,
    detail: action.payload,
    status: 'succeeded',
  };
};

const ProductReducer = helper.createReducer(initialState, {
  [types.SET_STATUS]: setStatus,
  [types.SET_LIST]: setList,
  [types.SET_DETAIL]: setDetail,
});

export default ProductReducer;
