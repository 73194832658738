import React from 'react';
import Layout from 'views/layout';

function App() {
  return (
    <>
      <Layout />
    </>
  );
}

export default App;
