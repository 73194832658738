import React from 'react';
import { Route, Switch } from 'react-router-dom';
import HomeContainer from 'views/pages/home';
import HtmlContainer from 'views/pages/html';
import { NewsListContainer, NewsDetailContainer } from 'views/pages/news';
import { ProductListContainer, ProductDetailContainer } from 'views/pages/product';

const MainRoutes = () => (
  <React.Suspense>
    <Switch>
      <Route exact path="/">
        <HomeContainer />
      </Route>
      <Route exact path="/:slug-:id-31.html">
        <HtmlContainer />
      </Route>
      <Route exact path="/:slug-:id-21.html">
        <NewsListContainer />
      </Route>
      <Route exact path="/:slug-:id-25.html">
        <NewsDetailContainer />
      </Route>
      <Route exact path="/:slug-:id-11.html">
        <ProductListContainer />
      </Route>
      <Route exact path="/:slug-:id-15.html">
        <ProductDetailContainer />
      </Route>
    </Switch>
  </React.Suspense>
);

MainRoutes.propTypes = {};

export default React.memo(MainRoutes);
