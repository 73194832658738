import { call, put, takeLatest, select, all, take } from 'redux-saga/effects';
import axios from 'axios';
import * as types from 'store/product/types';
import * as actions from 'store/product/actions';
import * as layoutTypes from 'store/layout/types';
import * as layoutActions from 'store/layout/actions';
import * as layoutSelectors from 'store/layout/selectors';

const fetchList = async () => {
  const res = await axios
    .get(`https://testreact.webdesign.edu.vn/api/product/root_id=1`)
    .then((response) => response)
    .catch((error) => {
      console.log(error);
    });

  return res;
};

const fetchDetail = async (id) => {
  const res = await axios
    .get(`https://testreact.webdesign.edu.vn/api/product/id=${id}`)
    .then((response) => response)
    .catch((error) => {
      console.log(error);
    });

  return res;
};

function* getList() {
  try {
    yield put(actions.setStatus('loading'));
    const list = yield call(fetchList);

    yield put(actions.setList(list.data));

    const status = yield select(layoutSelectors.getStatus);
    if (status !== 'succeeded') {
      yield take(layoutTypes.SET_DATA);
    }

    const menu = yield select(layoutSelectors.getMenu);
    const menuActive = menu.find((item) => item.active === 'product-list-1') || {};
    const { title, description, keyword, active } = menuActive;

    yield put(
      layoutActions.setSeo({
        title,
        description,
        keyword,
        active,
      })
    );
  } catch (e) {
    console.log(e);
  }
}

function* getDetail(action) {
  try {
    yield put(actions.setStatus('loading'));
    const [list, detail] = yield all([
      call(fetchList),
      call(fetchDetail, action.payload),
    ]);
    yield put(actions.setList(list.data));
    yield put(actions.setDetail(detail.data));

    const { title, description, keyword, active = 'product-list-1' } = detail.data;

    yield put(
      layoutActions.setSeo({
        title,
        description,
        keyword,
        active,
      })
    );
  } catch (e) {
    console.log(e);
  }
}

export default function* htmlSagas() {
  yield takeLatest(types.GET_LIST, getList);
  yield takeLatest(types.GET_DETAIL, getDetail);
}
