import helper from 'store/helper';
import * as types from 'store/html/types';

const initialState = {
  status: 'idle',
  aboutUs: {},
  chooseUs: { name: '', items: [] },
};

const setData = (state, action) => {
  return {
    ...state,
    aboutUs: action.payload.aboutUs,
    chooseUs: action.payload.chooseUs,
    status: 'succeeded',
  };
};

const setStatus = (state, action) => {
  return {
    ...state,
    status: action.payload.status,
  };
};

const HtmlReducer = helper.createReducer(initialState, {
  [types.SET_DATA]: setData,
  [types.SET_STATUS]: setStatus,
});

export default HtmlReducer;
