import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getList } from 'store/news/actions';

function NewsListContainer() {
  const dispatch = useDispatch();
  const list = useSelector((state) => state.news.list);

  useEffect(() => {
    dispatch(getList());
  }, [dispatch]);

  return (
    <>
      <div className="s-banner-1">
        <div className="container">
          <div className="s_content">
            <h2 className="title">Blog</h2>
            <ul className="s-breadcrumb-1">
              <li>
                <a href="index.php">Home</a>
              </li>
              <li>
                <span>Blog</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="container ht-mrt45">
        <div className="row row-flex">
          {list.map((item) => {
            return (
              <div className="col-6 col-md-4">
                <Link
                  to={`${item.slug}-${item.id}-25.html`}
                  className="b-card-42"
                >
                  <div className="b_top">
                    <div className="b_thumb ht-ratio--75">
                      <img
                        src={`${process.env.REACT_APP_HOST}/uploads/images/2021/08/385x289-${item.thumbnail}`}
                        alt=""
                        className="ht-ratio__img"
                      />
                    </div>
                  </div>
                  <div className="b_date">
                    <i className="fa fa-calendar-o" aria-hidden="true" />
                    <span>{item.date_created}</span>
                  </div>
                  <h3 className="b_title">{item.name}</h3>
                </Link>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default NewsListContainer;
