import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getList } from 'store/product/actions';

function ProductListContainer() {
  const dispatch = useDispatch();
  const list = useSelector((state) => state.product.list);

  useEffect(() => {
    dispatch(getList());
  }, [dispatch]);

  return (
    <>
      <div className="s-banner-1">
        <div className="container">
          <div className="s_content">
            <h2 className="title">Product</h2>
            <ul className="s-breadcrumb-1">
              <li>
                <a href="index.php">Home</a>
              </li>
              <li>
                <span>Product</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="container ht-mrt45">
        <div className="row row-flex">
          {list.map((item) => {
            return (
              <div className="col-12 col-sm-6">
                <Link
                  to={`${item.slug}-${item.id}-15.html`}
                  className="b-card-77"
                >
                  <div className="row">
                    <div className="col-12 col-md-5">
                      <div className="b_content">
                        <div className="b_sub">{item.cat_name}</div>
                        <h3 className="b_title">{item.name}</h3>
                        <div className="ht-format-detail">{item.detail}</div>
                        <div className="b_more">
                          <span>Show more</span>
                          <i
                            className="fa fa-long-arrow-right"
                            aria-hidden="true"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-7">
                      <div className="b_thumb ht-ratio--100">
                        <img
                          src={`${process.env.REACT_APP_HOST}/uploads/images/2021/08/296x296-${item.thumbnail}`}
                          alt={item.name}
                          className="ht-ratio__img"
                        />
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default ProductListContainer;
