import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getDetail } from 'store/product/actions';
import { useParams } from 'react-router-dom';

import 'assets/css/product-detail.css';

function ProductDetailContainer() {
  const dispatch = useDispatch();
  const { id: paramId } = useParams();
  const id = paramId.split('-').pop();
  const detail = useSelector((state) => state.product.detail);

  useEffect(() => {
    dispatch(getDetail(id));
  }, [dispatch, id]);

  return (
    <>
      <div className="s-banner-1">
        <div className="container">
          <div className="s_content">
            <h2 className="title">Coffee Shop Roaster</h2>
            <ul className="s-breadcrumb-1">
              <li>
                <a href="index.php">Home</a>
              </li>
              <li>
                <span>Product</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="p-detail-69">
        <div className="p_top">
          <div className="container align-items--between">
            <h3 className="title">Coffee Shop Roaster</h3>
            <ul>
              <li>
                <a href="#Introduce" className="data-scroll">
                  Introduce
                </a>
              </li>
              <li>
                <a href="#Specifications" className="data-scroll">
                  Specifications
                </a>
              </li>
              <li>
                <a href="#Warranty" className="data-scroll">
                  Warranty
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="p_box">
          <div className="container">
            <div className="row row-flex">
              <div className="col-12 col-sm-5">
                <div className="p_content">
                  <div className="sub">Coffee Shop Roaster</div>
                  <h3 className="title">{detail.name}</h3>
                  <div className="ht-format-detail">{detail.detail}</div>
                  <div className="desc">{detail.desc_short}</div>
                  <a
                    href="javascript:;"
                    ht-trigger="c-modal"
                    ht-target="#formregister"
                    className="c-button-8"
                  >
                    contact to order
                    <i className="fa fa-long-arrow-right" />
                  </a>
                </div>
              </div>
              <div className="col-12 col-sm-7 pdl5">
                <div className="p_thumb ht-ratio--100">
                  <img
                    src={`${process.env.REACT_APP_HOST}/uploads/images/2021/08/${detail.thumbnail}`}
                    alt={detail.name}
                    className="ht-ratio__img"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="p_detail">
          <div className="container">
            <div className="row">
              <div className="col-md-8">
                <div className="detail" id="Introduce">
                  <div className="c-headline-13">
                    <h2 className="c_name">Introduce</h2>
                  </div>
                  <div
                    className="ht-format-detail"
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                      __html: detail.multi_detail?.introduce,
                    }}
                  />
                </div>
                <div className="detail" id="Warranty">
                  <div className="c-headline-13">
                    <h2 className="c_name">Warranty</h2>
                  </div>
                  <div
                    className="ht-format-detail" // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                      __html: detail.multi_detail?.warranty,
                    }}
                  />
                </div>
              </div>
              <div className="col-md-4 sticky" id="Specifications">
                <div className="p_sidebar">
                  <h3 className="title">Specifications</h3>
                  <div
                    className="ht-format-detail"
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                      __html: detail.multi_detail?.specifications,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="formregister" className="c-modal c-modal-1" ht-close="c-modal">
        <div className="c-modal-box">
          <div className="c-modal-group" ht-skip="parent">
            <div className="c_close text--white" ht-close="c-modal">
              <i className="fa fa-times" aria-hidden="true" />
            </div>
            <div className="c_body">
              <h3>SR60 Coffee Roaster</h3>
              <div className="form">
                <form
                  id="form_form-1"
                  action=""
                  method="POST"
                  acceptCharset="utf-8"
                  encType="multipart/form-data"
                >
                  <div className="form-group c-form-border c-form-border--icon is-empty">
                    <span className="c_icon">
                      <i className="fa fa-user text--icon" aria-hidden="true" />
                    </span>
                    <input
                      type="text"
                      name="fullname"
                      className="c_input"
                      value=""
                      placeholder="Fullname (*)"
                    />
                  </div>
                  <div className="form-group c-form-border c-form-border--icon is-empty">
                    <span className="c_icon">
                      <i
                        className="fa fa-phone text--icon"
                        aria-hidden="true"
                      />
                    </span>
                    <input
                      type="text"
                      name="phone"
                      className="c_input"
                      value=""
                      placeholder="Phone (*)"
                    />
                  </div>
                  <div className="form-group c-form-border c-form-border--icon is-empty">
                    <span className="c_icon">
                      <i
                        className="fa fa-envelope text--icon"
                        aria-hidden="true"
                      />
                    </span>
                    <input
                      type="text"
                      name="email"
                      className="c_input"
                      value=""
                      placeholder="Email"
                    />
                  </div>
                  <div className="form-group c-form-border c-form-border--icon is-empty">
                    <span className="c_icon c_icon--top">
                      <i
                        className="fa fa-info-circle text--icon"
                        aria-hidden="true"
                      />
                    </span>
                    <textarea
                      name="content"
                      className="c_input"
                      rows="4"
                      placeholder="More Information"
                    />
                  </div>
                  <div className="form-group text-center">
                    <button type="submit" className="c-btn--secondary">
                      CONTACT US FOR QUOTATIONS
                    </button>
                  </div>
                </form>
              </div>
              <div className="thumb">
                <img src="images/icons/qr.png" alt="" />
                <h4>WhatsApp QR code</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProductDetailContainer;
