import React from 'react';
import { Helmet } from 'react-helmet';
import { shape } from 'prop-types';

function Head(props) {
  const { info, seo } = props;

  return (
    <Helmet>
      <title>{seo.title}</title>
      <meta name="description" content={seo.description} />
      <meta name="keywords" content={seo.keyword} />
      <meta name="web_author" content="HitechGroup.vn" />
      <meta itemProp="name" content={seo.title} />
      <meta
        itemProp="image"
        content={`${process.env.REACT_APP_HOST}/uploads/images/2021/08/${info.image}`}
      />
      <meta itemProp="description" content={seo.description} />
      <meta property="og:locale" content="vi_VN" />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={process.env.REACT_APP_HOST} />
      <meta property="og:title" content={seo.title} />
      <meta property="og:description" content={seo.description} />
      <meta
        property="og:image"
        content={`${process.env.REACT_APP_HOST}/uploads/images/2021/08/${info.image}`}
      />
      <meta
        property="og:image:alt"
        content={`${process.env.REACT_APP_HOST}/uploads/images/2021/08/${info.image}`}
      />
      <meta property="og:site_name" content="Sure CCTV" />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={seo.title} />
      <meta name="twitter:description" content={seo.description} />
      <meta
        name="twitter:image"
        content={`${process.env.REACT_APP_HOST}/uploads/images/2021/08/${info.image}`}
      />
      <meta
        name="twitter:image:alt"
        content={`${process.env.REACT_APP_HOST}/uploads/images/2021/08/${info.image}`}
      />
      <link
        href={`${process.env.REACT_APP_HOST}/uploads/images/2021/08/16x16-${info.favicon}`}
        rel="shortcut icon"
        type="image/vnd.microsoft.icon"
      />
      <link
        href={`${process.env.REACT_APP_HOST}/uploads/images/2021/08/32x32-${info.favicon}`}
        rel="icon"
      />
      <link
        href={`${process.env.REACT_APP_HOST}/uploads/images/2021/08/180x180-${info.favicon}`}
        rel="apple-touch-icon"
        sizes="180x180"
      />
    </Helmet>
  );
}

Head.propTypes = {
  seo: shape({}),
  info: shape({}),
};

Head.defaultProps = {
  seo: {},
  info: {},
};

export default Head;
