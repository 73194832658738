import { call, put, takeLatest, all, select, take } from 'redux-saga/effects';
import axios from 'axios';
import * as types from 'store/html/types';
import * as actions from 'store/html/actions';
import * as layoutTypes from 'store/layout/types';
import * as layoutActions from 'store/layout/actions';
import * as layoutSelectors from 'store/layout/selectors';

const fetchHtml = async (id) => {
  const res = await axios
    .get(`https://testreact.webdesign.edu.vn/api/html/id=${id}`)
    .then((response) => response)
    .catch((error) => {
      console.log(error);
    });

  return res;
};

function* getData() {
  try {
    yield put(actions.setStatus('loading'));
    const [aboutUs, chooseUs] = yield all([
      call(fetchHtml, 1),
      call(fetchHtml, 2),
    ]);

    yield put(
      actions.setData({
        aboutUs: {
          headline: aboutUs.data.multi_input['text-headline'],
          overviewName: aboutUs.data.name,
          overviewContent: aboutUs.data.detail,
          profileName: aboutUs.data.multi_input['tieu-de-profile'],
          profileContent: aboutUs.data.multi_detail.profile,
          profileDownload: aboutUs.data.multi_file.profile[0].file,
          information: aboutUs.data.multi_detail['thong-tin-them'],
          certificate: aboutUs.data.multi_image['chung-nhan'],
          images: aboutUs.data.multi_image['hinh-anh'],
        },
        chooseUs: {
          name: chooseUs.data.name,
          items: chooseUs.data.sub,
        },
      })
    );

    const status = yield select(layoutSelectors.getStatus);
    if (status !== 'succeeded') {
      yield take(layoutTypes.SET_DATA);
    }
    const menu = yield select(layoutSelectors.getMenu);
    const menuActive = menu.find((item) => item.active === 'html-page-1') || {};
    const { title, description, keyword, active } = menuActive;

    yield put(
      layoutActions.setSeo({
        title,
        description,
        keyword,
        active,
      })
    );
  } catch (e) {
    console.log(e);
  }
}

export default function* htmlSagas() {
  yield takeLatest(types.GET_DATA, getData);
}
