import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getDetail } from 'store/news/actions';
import { useParams, Link } from 'react-router-dom';

import 'assets/css/news-detail.css';

function NewsDetailContainer() {
  const dispatch = useDispatch();
  const { id: paramId } = useParams();
  const id = paramId.split('-').pop();
  const list = useSelector((state) => state.news.list);
  const detail = useSelector((state) => state.news.detail);

  useEffect(() => {
    dispatch(getDetail(id));
  }, [dispatch, id]);

  return (
    <>
      <div className="s-banner-1">
        <div className="container">
          <div className="s_content">
            <h2 className="title">Blog</h2>
            <ul className="s-breadcrumb-1">
              <li>
                <a href="index.php">Home</a>
              </li>
              <li>
                <span>Blog</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="p-detail-4">
        <div className="container">
          <div className="p_box">
            <h1 className="p_title">{detail.name}</h1>
            <h3 className="p_desc">{detail.desc_short}</h3>
            <div
              className="ht-format-detail"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: detail.detail }}
            />
            <div className="p_other">
              <div className="c-headline-12">
                <h2 className="c_name">
                  <span>Other Posts</span>
                </h2>
              </div>
              <ul>
                {list
                  .filter((item) => item.id !== id)
                  .map((item) => {
                    return (
                      <li>
                        <Link to={`${item.slug}-${item.id}-25.html`}>
                          {item.name}
                        </Link>
                        <ul className="p_info align-items">
                          <li>
                            <span className="fa fa-calendar">
                              {item.date_created}
                            </span>
                          </li>
                        </ul>
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NewsDetailContainer;
