const createReducer = (initialState, handlers) => {
  return function reducer(state = initialState, action) {
    if (Object.prototype.hasOwnProperty.call(handlers, action.type)) {
      return handlers[action.type](state, action);
    }
    return state;
  };
};

const createAction = (...args) => {
  if (args.length === 1) {
    return {
      type: args[0],
    };
  }
  return {
    type: args[0],
    payload: args[1],
    scope: args[2],
  };
};

export const STATUS_IDLE = 'idle';
export const STATUS_LOADING = 'loading';
export const STATUS_FAIL = 'failed';
export const STATUS_ERROR = 'error';
export const STATUS_SUCCEEDED = 'succeeded';

export default {
  createReducer,
  createAction,
};
