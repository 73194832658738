import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import rootSaga from './rootSaga';
import rootReducer from './rootReducer';

// Error handling middleware
// import LoggerServiceMiddleware from 'services/error/LoggerService';

// eslint-disable-next-line import/no-mutable-exports
export let store = null;

export default function configureStore() {
  const sagaMiddleware = createSagaMiddleware();

  // const middlewares = [sagaMiddleware, LoggerServiceMiddleware];
  const middlewares = [sagaMiddleware];
  const middlewareEnhancer = applyMiddleware(...middlewares);

  const enhancers = [middlewareEnhancer];
  const composeEnhancers =
    // Disable redux devtools in production
    (process.env.NODE_ENV !== 'production' &&
      typeof window !== 'undefined' &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
    compose;

  const myStore = createStore(rootReducer, composeEnhancers(...enhancers));

  store = myStore;

  // if (process.env.NODE_ENV !== 'production' && module.hot) {
  //   module.hot.accept('store', () => store.replaceReducer(rootReducer));
  // }

  sagaMiddleware.run(rootSaga);

  return store;
}
