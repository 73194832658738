import { combineReducers } from 'redux';

import LayoutReducer from 'store/layout';
import HomeReducer from 'store/home';
import HtmlReducer from 'store/html';
import NewsReducer from 'store/news';
import ProductReducer from 'store/product';

const appReducer = combineReducers({
  layout: LayoutReducer,
  home: HomeReducer,
  html: HtmlReducer,
  news: NewsReducer,
  product: ProductReducer,
});

// Create wrapper reducer for root reducer
const rootReducer = (state, action) => {
  return appReducer(state, action);
};

export default rootReducer;
