import React from 'react';
import { shape } from 'prop-types';

function Footer(props) {
  const { info } = props;
  return (
    <>
      <div className="s-footer-8">
        <div className="s_top">
          <div className="container">
            <div className="ft-logo">
              <img
                src={`${process.env.REACT_APP_HOST}/uploads/images/2021/08/138x80-${info.logo}`}
                alt={info.name}
              />
            </div>
            <div className="ft-info">
              <span className="text-bold text-uppercase">
                <h1>{info.name}</h1>
              </span>
              <span>
                <i className="fa fa-map-marker" aria-hidden="true" />
                <span>{info.address}</span>
              </span>
            </div>
            <div className="ft-contact">
              <span>
                <i className="fa fa-phone" aria-hidden="true" />
                <span>{`${info?.multi_input?.['dien-thoai']} - ${info?.multi_input?.hotline}`}</span>
              </span>
              <span>
                <i className="fa fa-envelope" aria-hidden="true" />
                <a href={`mailto:${info.email}`}>{info.email}</a>
              </span>
              <span>
                <i className="fa fa-globe" aria-hidden="true" />
                <a href={info?.multi_input?.website}>
                  {info?.multi_input?.website}
                </a>
              </span>
            </div>
            <div className="ft-social">
              <a href="" rel="nofollow" className="c-icon-bg--fb">
                <i className="fa fa-facebook" aria-hidden="true" />
              </a>
              <a href="" rel="nofollow" className="c-icon-bg--ins">
                <i className="fa fa-instagram" aria-hidden="true" />
              </a>
              <a href="" rel="nofollow" className="c-icon-bg--pr">
                <i className="fa fa-pinterest" aria-hidden="true" />
              </a>
            </div>
          </div>
        </div>
        <div className="s_bot">
          <div className="container align-items--center">
            <h6 className="ft-copyright">
              &#169; 2021 vinaroaster.com. All Rights Reserved.
            </h6>
          </div>
        </div>
        <div className="s_backtop">
          <a href={`tel:${info?.multi_input?.hotline}`} className="ft-phone">
            <i
              className="fa fa-phone c-animate-loopflicker"
              aria-hidden="true"
            />
            <span className="text-hide">{info?.multi_input?.hotline}</span>
          </a>
        </div>
      </div>
      <div className="s-icon-2">
        <a href="javascript:;" className="c-icon" ht-trigger="backtop">
          <i className="fa fa-angle-up text--primary" />
        </a>
        <a
          href="javascript:;"
          rel="nofollow noopener noreferrer"
          target="_blank"
          data-detect-zalo='{"desktop":"https://chat.zalo.me/?phone=0902304818","mobile":"https://zalo.me/0969874640"}'
        >
          <span className="c-icon">
            <img src="images/icons/zalo-img.png" alt="zalo-img.png" />
          </span>
        </a>
      </div>
      <div className="s-icon-3">
        <ul>
          <li>
            <a
              href="https://www.google.com/maps/place/15+Tr%E1%BA%A7n+H%E1%BA%A3i+Ph%E1%BB%A5ng,+V%C4%A9nh+L%E1%BB%99c+B,+B%C3%ACnh+Ch%C3%A1nh,+Th%C3%A0nh+ph%E1%BB%91+H%E1%BB%93+Ch%C3%AD+Minh,+Vi%E1%BB%87t+Nam/@10.7674683,106.567654,17z/data=!3m1!4b1!4m5!3m4!1s0x31752cee21614161:0x39dcf3b7f8f689ee!8m2!3d10.7674683!4d106.5698427?hl=vi-VN"
              rel="nofollow noopener noreferrer"
              target="_blank"
            >
              <img src="images/icons/icon-map.png" alt="icon-mes.png" />
              <span>Map</span>
            </a>
          </li>
          <li>
            <a href="sms:0909888888">
              <img src="images/icons/icon-mes.png" alt="icon-mes.png" />
              <span>Message</span>
            </a>
          </li>
          <li className="hotline">
            <a href="tel:0909888888">
              <img src="images/icons/icon-hotline.png" alt="icon-hotline.png" />
              <span>Call us</span>
            </a>
          </li>
          <li>
            <a
              href="javascript:;"
              rel="nofollow noopener noreferrer"
              target="_blank"
              data-detect-zalo=""
            >
              <img src="images/icons/icon-zalo.png" alt="icon-zalo.png" />
              <span>Chat zalo</span>
            </a>
          </li>
          <li>
            <a href="" rel="nofollow noopener" target="_blank">
              <img src="images/icons/icon-fb.png" alt="icon-fb.png" />
              <span>Facebook</span>
            </a>
          </li>
        </ul>
      </div>
    </>
  );
}

Footer.propTypes = {
  info: shape({}),
};

Footer.defaultProps = {
  info: {},
};

export default Footer;
